define("discourse/plugins/discourse-discover/discourse/api-initializers/screenshot-button", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/api"], function (_exports, _ajax, _ajaxError, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.13.0", api => {
    api.addPostAdminMenuButton(attrs => {
      const siteSettings = api.container.lookup("site-settings:main");
      const discoverCategoryId = parseInt(siteSettings.discourse_discover_category, 10);
      if (attrs.firstPost && attrs.topic.category_id === discoverCategoryId) {
        return {
          action: post => {
            (0, _ajax.ajax)(`/admin/plugins/discourse-discover/screenshot/${post.topic_id}.json`, {
              type: "PUT"
            }).catch(_ajaxError.popupAjaxError);
          },
          icon: "anchor",
          className: "screenshot-update",
          label: "discourse_discover.update_screenshot"
        };
      }
    });
  });
});